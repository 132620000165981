<template>
  <div class="mt-1 mx-3">
    <company-treasuries-create></company-treasuries-create>
  </div>
</template>

<script>
import CompanyTreasuriesCreate from "@/components/profile/treasuries/company/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CompanyTreasuriesCreate,
  },
  created() {
    this.notifyQueue({
      text: "Forme di pagamento di Compagnia",
      path: "module.PROF.CAGG",
      tab: "CompanyTreasuries",
      level: 3,
    });
    this.notifyQueue({
      text: "Crea Forme di pagamento di Compagnia",
      path: "treasuries.create1",
      level: 4,
    });
  },
};
</script>
