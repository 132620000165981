<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <b-input-group class="mb-3">
        <b-form-input
          :id="`input-${id}`"
          v-model="inputVal"
          type="text"
          :state="getValidationState({ dirty, validated, valid, errors })"
          :aria-describedby="`input-${id}-live-feedback`"
          placeholder="hh:mm"
          @input="onTextInput"
          :formatter="eight"
        ></b-form-input>
        <b-input-group-append>
          <b-form-timepicker
            v-model="inputVal"
            button-only
            right
            locale="it"
            aria-controls="start-input"
            button-variant="lisaweb"
            @input="onInput"
          ></b-form-timepicker>
        </b-input-group-append>
      </b-input-group>

      <!-- <p class="mb-1">Value: '{{ value }}'</p>
          <p class="mb-1">Selected: '{{ selected }}'</p>
          <p>Formatted: '{{ formatted }}'</p> -->

      <!-- <p>Value: '{{ inputVal }}'</p> -->
      <!-- :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" -->
      <!-- value-as-date -->
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: String,
    vid: String,
    name: String,
    label: String,
    type: {
      default: "text",
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
      formatted: "",
      selected: "",
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    eight(e) {
      return String(e).substring(0, 8);
    },
    // getValidationState({ dirty, validated, valid = null }) {
    //     return dirty || validated ? valid : null;
    // },
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    // onBlur(e) {
    //     console.debug("input onBlur", e)
    //     this.$emit('blur', e)
    // },
    onInput(time) {
      // console.debug("input onInput", time);
      this.$emit("select", time);
    },
    onTextInput(time) {
      // console.debug("input onTextInput", time);
      let v = time;
      if (v.match(/^\d{2}$/) !== null) {
        time = v + ":";
      }
      this.inputVal = time;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
  },
};
</script>
