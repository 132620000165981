<template>
  <div class="mt-1 mx-3">
    <company-treasuries-edit> </company-treasuries-edit>
  </div>
</template>

<script>
import CompanyTreasuriesEdit from "@/components/profile/treasuries/company/edit.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
    ...mapActions("breadcrumbs", { removeBCS: "remove" }),
    ...mapGetters("breadcrumbs", { loadBCS: "loadBreadcrumbs" }),
  },
  components: {
    CompanyTreasuriesEdit,
  },
  beforeMount() {
    let bcs = this.loadBCS();
    if (this.$route.query.fromShortcut) {
      bcs.push({
        text: "Forme di pagamento di Compagnia",
        path: "module.PROF.CAGG",
        tab: "CompanyTreasuries",
        level: 3,
      });
      bcs.push({
        text: "Modifica Forme di pagamento di Compagnia",
        path: "treasuries.edit1",
        level: 4,
      });
      bcs.forEach((element) => {
        this.notifyQueue({
          text: element.text,
          path: element.path,
          tab: element.tab,
          level: element.level,
          disabled: element.disabled,
        });
      });
      // this.$router.replace({ query: null });
    } else {
      this.notifyQueue({
        text: "Forme di pagamento di Compagnia",
        path: "module.PROF.CAGG",
        tab: "CompanyTreasuries",
        level: 3,
      });
      this.notifyQueue({
        text: "Modifica Forme di pagamento di Compagnia",
        path: "treasuries.edit",
        level: 4,
      });
    }
  },
};
</script>
