<template>
  <div class="mt-1 mx-3">
    <company-treasuries> </company-treasuries>
  </div>
</template>

<script>
import CompanyTreasuries from "@/components/profile/treasuries/company/index.vue";
import { mapActions } from 'vuex';

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CompanyTreasuries,
  },
  created() {
    this.notifyQueue({ text: "Forme di pagamento di Compagnia", path: "treasuries.index1", level: 1 });
  },
};
</script>
